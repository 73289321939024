import Project from "./Project";
import "./Projects.css";
import ConfigEditor from "../images/config-editor.jpg";
import Oxplorer from "../images/oxplorer.png";

export default function Projects() {
  return (
    <div className="projectsContainer">
      <h1>Projects</h1>
      <p>Main projects I've worked on and am still actively supporting</p>
      <div className="projects">
        <Project
          title="OxConfig"
          description="A dynamic tuner/config editor for FRC robots"
          images={[ConfigEditor]}
          links={[
            { url: "https://github.com/FRCTeam3044/OxConfig", text: "GitHub" },
          ]}
        />
        <Project
          title="Oxplorer"
          description="A real-time dynamic pathfinder for FRC robots"
          links={[
            { url: "https://github.com/FRCTeam3044/Oxplorer", text: "GitHub" },
          ]}
          images={[Oxplorer]}
        />
        <Project
          title="AdvantageScope-3044"
          description="A fork of AdvantageScope adding extra tabs that can be used for debugging, OxConfig, and Oxplorer. I did not make this from scratch, it is a fork of the original AdvantageScope by Team 6328 Mechanical Advantage."
          links={[
            {
              url: "https://github.com/FRCteam3044/AdvantageScope-3044",
              text: "GitHub",
            },
          ]}
        />
        <Project
          title="More Projects"
          links={[
            { url: "/projects/fun", text: "Fun Projects" },
            { url: "/projects/archived", text: "Archived Projects" },
          ]}
        />
      </div>
    </div>
  );
}
