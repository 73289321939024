import React, { useState, useEffect } from "react";
import "./Typewriter.css";
const texts = [
  "Frontend",
  "Backend",
  "TypeScript",
  "C#",
  "Python",
  "HTML",
  "Java",
  "Kotlin",
  "Rust",
  "JavaScript",
];

export default function Typewriter() {
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleType();
    }, typingSpeed);
    return () => clearTimeout(timer);
  });

  const handleType = () => {
    const i = loopNum % texts.length;
    const fullText = texts[i];

    setText(
      isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1)
    );

    setTypingSpeed(isDeleting ? 30 : 150);

    if (!isDeleting && text === fullText) {
      setTimeout(() => setIsDeleting(true), 500);
    } else if (isDeleting && text === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
    }
  };

  return (
    <div className="typewriter">
      <h1>{text}</h1>
      <div id="cursor"></div>
    </div>
  );
}
