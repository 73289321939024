import Project from "./Project";
import "./Projects.css";
import Escaper from "../images/escaper.png";

export default function ArchivedProjects() {
  return (
    <div className="projectsContainer">
      <h1>Archived Projects</h1>
      <p>Projects that are no longer maintained or are no longer relevant.</p>
      <div className="projects">
        <Project
          title="EnmityPlugins"
          description="Plugins I made for Emnity, an iOS discord client"
          useatags
          links={[
            {
              url: "https://github.com/nab138/EnmityPlugins",
              text: "GitHub",
            },
            {
              url: "https://nabdev.me/plugins",
              text: "Plugin Store",
            },
          ]}
        />
        <Project
          title="NetworkTables-CSharp"
          description="A very underdeveloped C# implementation of NetworkTables. This might be revisited in the future."
          images={[Escaper]}
          links={[
            {
              url: "https://github.com/nab138/NetworkTables-CSharp",
              text: "GitHub",
            },
          ]}
        />
        <Project
          title="More Projects"
          links={[
            { url: "/projects", text: "Main Projects" },
            { url: "/projects/fun", text: "Fun Projects" },
          ]}
        />
      </div>
    </div>
  );
}
