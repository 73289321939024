import "./About.css";

export default function About() {
  return (
    <div className="aboutContainer">
      <div className="aboutInsideContainer">
        <div className="about">
          <h2>About Me</h2>
          <p className="aboutText">
            I'm Nick, a high school student from the US. I'm currently on the
            FRC robotics team 3044, and I've worked on several libraries for the
            team. I write many different programs, from standalone desktop apps
            to websites to libraries. I also have some experience working with
            iDevices (sideloading, jailbreaking, etc.), and I've made a few very
            simple mobile apps and tweaks.
          </p>
        </div>
        <div className="about">
          <h2>Services</h2>
          <p className="aboutText">
            I can make cross platform desktop apps, utilities, libraries, and
            more. If you're interested, send me an email or a message on any of
            the platforms linked on the contact page and I'll get back to you as
            soon as possible.
          </p>
        </div>
        <div className="about">
          <h2>Languages</h2>
          <p className="aboutText">
            Recently, I've been mostly working with Java and
            JavaScript/TypeScript, thought I have a good amount of experience
            with C#. I also am familiar with rust, python, kotlin, and HTML/CSS.
          </p>
        </div>
        <div className="about">
          <h2>Frameworks</h2>
          <p className="aboutText">
            Some of the frameworks/engines I've worked with include WPILIB,
            React, Unity, Electron, Tauri, Discord.js, and more. I'm currently
            learning and using motioncanvas as well to make some video
            explanations of some of my more math-based projects.
          </p>
        </div>
        <div className="about">
          <h2>System</h2>
          <p className="aboutText">
            I dual boot Linux Mint 21.2 (Have been daily driving for a few
            years) and windows 11. I use VSCode as my main editor, but I
            sometimes use IntelliJ IDEA and JetBrains Rider as well.
          </p>
        </div>
      </div>
    </div>
  );
}
