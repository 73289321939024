import "./404.css";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="NotFound">
      <div>
        <h1>404</h1>
        <h2>Not Found</h2>
      </div>
      <div>
        <Link className="btn-link" to="/">
          Take me home
        </Link>
      </div>
    </div>
  );
}
