/* Represents a single project for displaying on the Projects page. */
import "./Project.css";
import { Link } from "react-router-dom";

export default function Project(props) {
  let images = props.images ?? [];
  let links = props.links ?? [];

  let imagesDiv = (
    <div className="projectImages">
      {images.map((image) => (
        <img className="projectImage" src={image} alt={props.title} />
      ))}
    </div>
  );
  return (
    <div className="project">
      <h2>{props.title}</h2>
      <p>{props.description}</p>
      <div className="projectContents">
        {images.length > 0 && imagesDiv}

        <div className="projectLinks">
          <ul className="projectLinkList">
            {links.map((link) => (
              <li className="projectLink">
                <p className="projectLink">
                  {!props.useatags && (
                    <Link className="projectLink" to={link.url}>
                      {link.text}
                    </Link>
                  )}
                  {props.useatags && (
                    <a className="projectLink" href={link.url}>
                      {link.text}
                    </a>
                  )}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
