import "./Contact.css";

export default function Contact() {
  return (
    <div className="contactContainer">
      <div className="contact">
        <h1>Contact</h1>
        <h3 className="contactText">
          For support, requests, questions, and more, feel free to reach out at
          any of the following:
        </h3>
        <div className="contactLinks">
          <p>
            <b>Email: </b>
            <a href="mailto:nab@nabdev.me">nab@nabdev.me</a>
          </p>
          <p>
            <b>Github: </b>
            <a href="https://github.com/nab138">nab138</a>
          </p>
          <p>
            <b>Discord: </b>
            nab138
          </p>
          <p>
            <b>ChiefDelphi: </b>
            <a href="https://www.chiefdelphi.com/u/nab138">nab138</a>
          </p>
        </div>
      </div>
    </div>
  );
}
