import Project from "./Project";
import "./Projects.css";
import DisadvantegeScope from "../images/disadvantagescope.gif";
import Escaper from "../images/escaper.png";
import ScreenToMap from "../images/screentomap.png";

export default function FunProjects() {
  return (
    <div className="projectsContainer">
      <h1>Fun Projects</h1>
      <p>
        Projects that don't serve a practical purpose but are just plain fun.
        Probably won't be maintained.
      </p>
      <div className="projects">
        <Project
          title="DisadvantageScope"
          description="A wrapper for the FRC tool AdvantageScope that runs in the terminal"
          images={[DisadvantegeScope]}
          links={[
            {
              url: "https://github.com/nab138/DisadvantageScope",
              text: "GitHub",
            },
          ]}
        />
        <Project
          title="Escaper"
          description="An escape room framework for discord.js bots"
          images={[Escaper]}
          links={[
            {
              url: "https://github.com/nab138/Escaper",
              text: "GitHub",
            },
          ]}
        />
        <Project
          title="ScreenToMap"
          description="Show your screen on some minecraft maps"
          images={[ScreenToMap]}
          links={[
            {
              url: "https://github.com/nab138/ScreenToMap",
              text: "GitHub",
            },
          ]}
        />
        <Project
          title="Minecraft Snake"
          description="A snake game in Minecraft"
          links={[
            {
              url: "https://github.com/nab138/MinecraftSnake",
              text: "GitHub",
            },
          ]}
        />
        <Project
          title="More Projects"
          links={[
            { url: "/projects", text: "Main Projects" },
            { url: "/projects/archived", text: "Archived Projects" },
          ]}
        />
      </div>
    </div>
  );
}
