import Typewriter from "./Typewriter";
import "./Landing.css";
import { Link } from "react-router-dom";

export default function Landing() {
  return (
    <div className="landing">
      <div>
        <Typewriter />
        <h3 style={{ marginTop: 0 }}>Nabdev</h3>
      </div>
      <div className="buttons">
        <Link className="btn-link" to="/about">
          About
        </Link>
        <Link className="btn-link" to="/projects">
          Projects
        </Link>
        <Link className="btn-link" to="/contact">
          Contact
        </Link>
        <a className="btn-link" href="https://github.com/nab138">
          Github
        </a>
      </div>
    </div>
  );
}
