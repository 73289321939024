import "./App.css";
import "typeface-roboto";
import Landing from "./components/Landing";
import About from "./components/About";
import Contact from "./components/Contact";
import Projects from "./components/Projects";
import FunProjects from "./components/FunProjects";
import NavBar from "./components/Navbar";
import NotFound from "./components/404";
import { Routes, Route } from "react-router-dom";
import ArchivedProjects from "./components/ArchivedProjects";

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route path="/projects/fun" element={<FunProjects />} />
        <Route path="/projects/archived" element={<ArchivedProjects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
